import { StrictMode } from "react";

import { ReactRouterV6History } from "@fastly/microfrontend-compositor/react-router-v6";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { createRouter } from "@remix-run/router";
import { Server } from "miragejs";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouteObject, RouterProvider } from "react-router-dom";

import { ACTIVE_SESSION__TOKEN_KEY, type TokenType } from "@fastly/auth-core";
import { Global, ThemeProvider } from "@fastly/beacon";
import { summit } from "@fastly/design-tokens";
import type { MountFn } from "@fastly/microfrontend-compositor";
import { AppRegistry } from "@fastly/shared-mirage";

import { api } from "api";
import { API } from "environment";
import { makeServer } from "mirage";

import { FASTLY_CUSTOMER_ID } from "../mirage/constants";

import { CustomerApp } from "./CustomerApp";
import { createCustomerStore } from "./state/store";

const adaptor = new ReactRouterV6History("comms-hub-customer-ui");

const reduxStore = createCustomerStore();

const router = createRouter({
  history: adaptor,
  routes: [
    {
      element: (
        <ThemeProvider theme={summit} tokenlyTheme="aspen">
          <Global normalize={false} reset />
          <ApiProvider api={api}>
            <Provider store={reduxStore}>
              <CustomerApp />
            </Provider>
          </ApiProvider>
        </ThemeProvider>
      ),
      path: "*",
    },
  ] as RouteObject[],
}).initialize();
adaptor.rr6Router = router;

export const mount: MountFn = async ({ container }) => {
  let server: Server<AppRegistry> | undefined;

  if (import.meta.env.MODE === "development") {
    if (import.meta.env.VITE_MIRAGE !== "passthrough") {
      // eslint-disable-next-line
      console.log(">>>>>>>> IN development mode, mirage turned ON");
      server = makeServer({ environment: "development", apiOrigin: API });
      server.timing = 0;
      server.logging = true;
    } else {
      // eslint-disable-next-line
      console.log(">>>>>>>> IN development mode, mirage set to PASSTHROUGH");
      const accessToken = import.meta.env.VITE_FASTLY_AUTH_TOKEN;
      if (accessToken) {
        const token: TokenType = {
          customerId: FASTLY_CUSTOMER_ID,
          accessToken,
          createdAt: new Date(),
          id: "0",
          updatedAt: new Date(),
        };
        const tokenString = JSON.stringify(token);
        sessionStorage.setItem(ACTIVE_SESSION__TOKEN_KEY, tokenString);
      } else {
        // eslint-disable-next-line
        console.log(
          "WARNING: no VITE_FASTLY_AUTH_TOKEN set in your .env file. Things will probably not work for you.",
        );
      }
    }
  }

  document.title = "Fastly - Messages";

  const root = createRoot(container);

  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );

  return async () => {
    server?.shutdown();
    root.unmount();
  };
};
