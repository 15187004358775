import { BaseModel } from "./base-model";

export type DeliveryAttrs = {
  _id: number;
  uuid: string;
  customerID: string;
  messageID: number;
  minimumRole: string;
  deliveryDate: string;
  createdDate: string;
  lastModifiedDate: string;
};

export const Delivery = BaseModel.extend<DeliveryAttrs>({
  _id: 0,
  uuid: "",
  customerID: "",
  messageID: 0,
  minimumRole: "",
  createdDate: "",
  deliveryDate: "",
  lastModifiedDate: "",
});
