import { IconFastlyLogoSquare } from "@fastly/beacon-icons/logos";
import { IconSupport } from "@fastly/beacon-icons/navigation-fastly";

import * as styles from "./Avatar.css";

const avatarMap = {
  default: IconFastlyLogoSquare,
  support: IconSupport,
};

const supportSenders = ["Customer support"];

const getAvatarIcon = (sender: string) => {
  const useSupportIcon = supportSenders.includes(sender);

  if (useSupportIcon) return avatarMap.support;

  return avatarMap.default;
};

type AvatarProps = {
  isUnread?: boolean;
  sender: string;
};

export const Avatar = ({ isUnread = false, sender }: AvatarProps) => {
  const AvatarIcon = getAvatarIcon(sender);

  return (
    <div className={styles.root} data-unread={isUnread}>
      <AvatarIcon width={20} />
    </div>
  );
};
