import { ComponentProps } from "react";

import { Button } from "@fastly/beacon";

import { useLazyDownloadFileQuery } from "api";

type ButtonProps = ComponentProps<typeof Button>;
type ButtonSize = ButtonProps["size"];
type ButtonVariant = ButtonProps["variant"];

export const FileDownloadButton = (props: {
  title: string;
  fileUUID: string;
  size?: ButtonSize;
  variant?: ButtonVariant;
}) => {
  const { title, fileUUID, size, variant } = props;
  const [downloadFile] = useLazyDownloadFileQuery();
  const downloadFileHandler = async () => {
    const res = await downloadFile({ fileUid: fileUUID });
    if (res.data) {
      window.open(res.data);
    }
  };
  return (
    <Button size={size} variant={variant} onClick={downloadFileHandler}>
      {title}
    </Button>
  );
};
