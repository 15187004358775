import { useCallback } from "react";

import { IconFeatures } from "@fastly/beacon-icons/navigation-fastly-admin";
import { useNavigate } from "react-router-dom";

import { Page, PageHeader, Pill, Button } from "@fastly/beacon";

import {
  usePaginatedGetMessageDeliveriesQuery,
  useGetMessagesUnreadCountQuery,
} from "api";
import { DeliveredMessageResponse } from "api/api.gen";
import { FullLoadingSpinner } from "beacon-staging/shell/Loading";
import { EmptyState } from "cch-components/EmptyState";
import { MessageCard } from "cch-components/MessageCard";
import { PaginationToolbar } from "cch-components/PaginationToolbar";
import { useUpdateReadState } from "customer/hooks/use-update-read-state";
import { getLocalDateStringFromUtcDate } from "dates/dateString";
import * as styleUtils from "styles/utils.css";

import * as styles from "./MessagesListPage.css";

export const MessagesListPage = () => {
  const navigate = useNavigate();

  const deliveries = usePaginatedGetMessageDeliveriesQuery(
    {},
    { defaultLimit: 5 },
  );

  const unreadCountQuery = useGetMessagesUnreadCountQuery();

  const handleNotificationNavigate = (id: string) => {
    navigate(`/account/notifications/${id}`);
  };

  const unreadCount = unreadCountQuery.isSuccess
    ? unreadCountQuery.data.unreadMessageCount
    : 0;

  return (
    <Page>
      <PageHeader>
        <PageHeader.Title>Your notifications</PageHeader.Title>

        <PageHeader.Decorations>
          {unreadCount > 0 ? (
            <Pill status="error" variant="filled">
              {unreadCount} unread
            </Pill>
          ) : null}
        </PageHeader.Decorations>
      </PageHeader>
      <Page.Body>
        <MessagesList
          deliveries={deliveries}
          handleNotificationNavigate={handleNotificationNavigate}
        />
      </Page.Body>
    </Page>
  );
};

type MessagesListProps = {
  deliveries: ReturnType<typeof usePaginatedGetMessageDeliveriesQuery>;
  handleNotificationNavigate: (messageId: string) => void;
};

const isMessageUnread = (message: DeliveredMessageResponse) =>
  typeof message.messageReadDate !== "undefined" &&
  message.messageReadDate === null;

function MessagesList({
  deliveries,
  handleNotificationNavigate,
}: MessagesListProps) {
  const { markAsRead, markAsUnread } = useUpdateReadState();

  const handleUpdateReadState = useCallback(
    (message: DeliveredMessageResponse) => {
      return () => {
        if (isMessageUnread(message)) {
          return markAsRead(message.uuid);
        }

        return markAsUnread(message.uuid);
      };
    },
    [markAsRead, markAsUnread],
  );

  const handleMarkAllAsRead = useCallback(async () => {
    if (deliveries?.data?.messages && deliveries.data.messages.length > 0) {
      const unreadMessages = deliveries.data.messages.filter(isMessageUnread);

      return Promise.all(
        unreadMessages.map((message) => markAsRead(message.uuid)),
      );
    }
  }, [deliveries, markAsRead]);

  if (!deliveries.data && deliveries.isFetching) {
    return <FullLoadingSpinner />;
  }

  if (deliveries.pagination.totalCount === 0) {
    return <EmptyState message="You don't have any messages yet" />;
  }

  return (
    <div className={styleUtils.stack.sm}>
      <div className={styles.toolbar}>
        <Button
          className={styleUtils.iconButton}
          variant="secondary"
          onClick={handleMarkAllAsRead}
        >
          <IconFeatures />
          Mark all as read
        </Button>
      </div>
      <ul className={styles.messageList}>
        {deliveries.data?.messages.map((message) => (
          <li key={message.id}>
            <MessageCard
              author={message.sender}
              content={message.messageBody}
              isUnread={isMessageUnread(message)}
              links={message.linkedContent || []}
              timestamp={getLocalDateStringFromUtcDate(
                message.messageDeliveryDate,
              )}
              title={message.messageTitle}
              onNavigate={() => handleNotificationNavigate(message.uuid)}
              onUpdateReadState={handleUpdateReadState(message)}
            />
          </li>
        ))}
      </ul>
      <PaginationToolbar pagination={deliveries.pagination} />
    </div>
  );
}
