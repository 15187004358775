import { ReactNode } from "react";

import { LinkedContent } from "api";

import { LinkedContentLink } from "./LinkedContentLink";
import * as styles from "./MessageLinks.css";

export interface MessageLinkListProps {
  children?: ReactNode;
}

export const MessageLinkList = ({
  children,
}: MessageLinkListProps): ReactNode => {
  return <div className={styles.wrapper}>{children}</div>;
};

export const MessageLinks = ({ links }: { links: LinkedContent[] }) => {
  return (
    <MessageLinkList>
      {links.map((link, i) => {
        if (i === 0) {
          return (
            <LinkedContentLink
              key={link.contentTitle}
              content={link}
              variant="primary"
            />
          );
        }

        return (
          <LinkedContentLink
            key={link.contentTitle}
            content={link}
            variant="simple"
          />
        );
      })}
    </MessageLinkList>
  );
};
