import { useEffect, useCallback } from "react";

import { useNavigate, useParams, Link } from "react-router-dom";

import { Alert, Button, Page, Breadcrumbs, Text } from "@fastly/beacon";
import { IconUnread } from "@fastly/beacon-icons";

import { api } from "api";
import { FullLoadingSpinner } from "beacon-staging/shell/Loading";
import { Message, MessageBody } from "cch-components/Message";
import { MessageHeader } from "cch-components/MessageHeader";
import { MessageLinks } from "cch-components/MessageLinks";
import { useUpdateReadState } from "customer/hooks/use-update-read-state";
import { getLocalDateStringFromUtcDate } from "dates/dateString";
import * as styleUtils from "styles/utils.css";

import * as styles from "./MessageDetails.css";

export interface MessageDetailsPageProps {
  uuid?: string;
}

const root = `/account/notifications`;

export const MessageDetailsPage = ({ uuid }: MessageDetailsPageProps) => {
  const { messageID: routerUUID } = useParams<{ messageID: string }>();
  const messageUuid = uuid ?? routerUUID ?? "";
  const navigate = useNavigate();

  const { data, isLoading, error } = api.useGetDeliveryByMessageUuidQuery({
    messageUuid,
  });

  const messageIsRead = data?.messageReadDate && data.messageReadDate !== null;

  const { markAsRead, markAsUnread } = useUpdateReadState();

  useEffect(() => {
    if (messageIsRead) return;
    /* We should only need to mark a message as read once, when loaded */
    markAsRead(messageUuid);
  }, [messageIsRead, messageUuid, markAsRead]);

  const handleMarkAsUnread = useCallback(async () => {
    await markAsUnread(messageUuid);
    return navigate(root);
  }, [messageUuid, navigate, markAsUnread]);

  return (
    <>
      {isLoading && <FullLoadingSpinner />}
      {error && <Alert status="error">This message failed to load.</Alert>}

      {!isLoading && !error && data && (
        <Page className={styles.root}>
          <Breadcrumbs>
            <Link to="/account/notifications">
              <Text element="span" size="md" status="action" weight="semibold">
                Your notifications
              </Text>
            </Link>
            <Text size="md" weight="normal">
              {data.messageTitle}
            </Text>
          </Breadcrumbs>
          <Message>
            <MessageHeader
              actions={
                <Button
                  className={styleUtils.iconButton}
                  variant="secondary"
                  onClick={handleMarkAsUnread}
                >
                  <IconUnread />
                  Mark unread
                </Button>
              }
              meta={{
                sender: `From: ${data.sender ?? "Unknown Sender"}`,
                timestamp: `Sent on: ${
                  getLocalDateStringFromUtcDate(data.messageDeliveryDate) ??
                  "1970-01-01 00:00"
                }`,
              }}
              title={data.messageTitle}
            />

            <div className={styleUtils.stack.md}>
              <MessageBody>{data.messageBody}</MessageBody>
              {data.linkedContent && data.linkedContent.length > 0 ? (
                <MessageLinks links={data.linkedContent} />
              ) : null}
            </div>
          </Message>
        </Page>
      )}
    </>
  );
};

export default MessageDetailsPage;
