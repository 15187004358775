export const FullLoadingSpinner = () => (
  <div className="loading-spinner__wrap">
    <svg
      className="loading-spinner"
      height="150px"
      style={{ animation: "rotate .8s infinite linear" }}
      version="1.1"
      viewBox="0 0 150 150"
      width="150px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Loading…</title>
      <g fill="transparent" stroke="currentColor">
        <path
          d="M3,75 C3,35.235498 35.235498,3 75,3 C114.764502,3 147,35.235498 147,75 C147,114.764502 114.764502,147 75,147 C35.235498,147 3,114.764502 3,75 Z"
          stroke="currentColor"
          strokeWidth="6"
        />

        <path
          className="loading-spinner__arc"
          d="M 125.91168824543144 24.08831175456858 A 72 72 0 0 0 75 3"
          stroke="currentColor"
          strokeWidth="6"
          transform="rotate(16596 75 75)"
        />
      </g>
    </svg>
  </div>
);
