import { FC, ComponentProps } from "react";

import { Button } from "@fastly/beacon";
import { IconExport } from "@fastly/beacon-icons";

import { FileDownloadButton } from "cch-components/FileDownloadButton";

import * as styleUtils from "../../styles/utils.css";

import { LinkedContent } from "./types";

type ButtonProps = ComponentProps<typeof Button>;
type ButtonSize = ButtonProps["size"];
type ButtonVariant = ButtonProps["variant"];

export type LinkedContentLinkProps = {
  content: LinkedContent;
  size?: ButtonSize;
  variant?: ButtonVariant;
};

export const LinkedContentLink: FC<LinkedContentLinkProps> = ({
  content,
  size,
  variant,
}) => {
  const { contentType, contentTitle, contentURL, contentFileUUID } = content;

  if (contentType === "url" && contentURL) {
    return (
      <Button
        className={styleUtils.iconButton}
        href={contentURL}
        size={size}
        variant={variant}
      >
        {contentTitle}
        <IconExport width={16} />
      </Button>
    );
  }

  if (contentType === "file" && contentFileUUID) {
    return (
      <FileDownloadButton
        fileUUID={contentFileUUID}
        size={size}
        title={contentTitle}
        variant={variant}
      />
    );
  }

  return null;
};
