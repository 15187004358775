import { cx } from "@emotion/css";

import { Button, Heading, Text, Truncation } from "@fastly/beacon";
import { IconCheck, IconUnread } from "@fastly/beacon-icons";

import { Avatar } from "cch-components/Avatar";
import {
  MessageLinkList,
  LinkedContentLink,
} from "cch-components/MessageLinks";
import { LinkedContent } from "cch-components/MessageLinks/types";
import { FormattableTimestamp, getDateString } from "dates/dateString";
import * as styleUtils from "styles/utils.css";

import * as styles from "./MessageCard.css";

export interface MessageCardProps {
  title: string;
  author: string;
  timestamp: number | string | FormattableTimestamp;
  content: string;
  links: LinkedContent[];
  onNavigate: () => void;
  isUnread?: boolean;
  isAdmin?: boolean;
  onUpdateReadState?: () => void;
}

export const MessageCard = ({
  title,
  author,
  timestamp,
  content,
  links,
  onNavigate,
  isAdmin,
  isUnread,
  onUpdateReadState,
}: MessageCardProps) => {
  const formattedDate =
    typeof timestamp === "string" ? timestamp : getDateString(timestamp);

  return (
    <div className={styles.wrapper}>
      <div className={styleUtils.stack.none}>
        <div className={styles.header}>
          <div className={cx(styleUtils.row.md, styleUtils.flex.alignStart)}>
            <Avatar isUnread={isUnread} sender={author} />
            <Heading element="h2" size="lg">
              {isUnread ? (
                <span className="visually-hidden">Unread notification:</span>
              ) : null}
              {title}
            </Heading>
          </div>
          {isAdmin ? null : (
            <div className={styles.headerActions}>
              <Button
                className={styleUtils.iconButton}
                size="compact"
                variant="simple"
                onClick={onUpdateReadState}
              >
                {isUnread ? (
                  <>
                    <IconCheck />
                    Mark as read
                  </>
                ) : (
                  <>
                    <IconUnread />
                    Mark unread
                  </>
                )}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div>
            <Text
              className={styles.meta}
              size="md"
              variant="secondary"
              weight="normal"
            >
              <span>From: {author}</span>
              <span aria-hidden>|</span>
              <span className={styles.timestamp}>{formattedDate}</span>
            </Text>
            <Text
              className={styles.preview}
              element="div"
              size="md"
              weight="normal"
            >
              <Truncation rows={2}>{content}</Truncation>
            </Text>
          </div>
          <div className={styleUtils.buttonBleed.compact}>
            <MessageLinkList>
              <Button size="compact" variant="simple" onClick={onNavigate}>
                View details
              </Button>
              {links && links.length > 0
                ? links.map((link) => (
                    <LinkedContentLink
                      key={link.contentTitle}
                      content={link}
                      size="compact"
                      variant="simple"
                    />
                  ))
                : null}
            </MessageLinkList>
          </div>
        </div>
      </div>
    </div>
  );
};
