import { Model } from "miragejs";
import moment from "moment";

export const BaseModel = Model.extend({
  save() {
    // @ts-expect-error attrs not typed correctly
    if (this.attrs.createdAt == null) {
      // @ts-expect-error attrs not typed correctly
      this.attrs.createdAt = moment.utc().toDate();
    }
    // @ts-expect-error attrs not typed correctly
    this.attrs.updatedAt = moment.utc().toDate();
    // @ts-expect-error model.prototype not typed correctly
    Model.prototype.save.apply(this, arguments); // eslint-disable-line
    return this;
  },
});
