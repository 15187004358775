import { ReactNode } from "react";

import { Heading, Text } from "@fastly/beacon";

import * as styleUtils from "styles/utils.css";

import * as styles from "./MessageHeader.css";

type MessageHeaderProps = {
  title: string;
  meta: {
    sender: string;
    timestamp: string;
  };
  actions?: ReactNode;
};

export const MessageHeader = ({ title, meta, actions }: MessageHeaderProps) => {
  return (
    <div className={styles.messageHeader}>
      <div className={styleUtils.stack.sm}>
        <Heading className={styles.title} element="h1" size="xl">
          {title}
        </Heading>
        <Text
          className={styles.meta}
          size="md"
          variant="secondary"
          weight="normal"
        >
          <span>{meta.sender}</span>
          <span aria-hidden>|</span>
          <span className={styles.timestamp}>{meta.timestamp}</span>
        </Text>
      </div>

      {actions ? <div className={styles.messageActions}>{actions}</div> : null}
    </div>
  );
};
