import { BaseModel } from "./base-model";

export type LinkedContentAttrs = {
  _id: number;
  messageID: number;
  messageUUID: string;
  uuid: string;
  contentType: "url" | "file";
  contentTitle: string;
  contentCreationDate: string;
  contentLastModifiedDate: string;

  contentURL?: string;
  contentFileUUID?: string;
  contentHTML?: string;
};

export const LinkedContent = BaseModel.extend<LinkedContentAttrs>({
  _id: 0,
  messageID: 0,
  messageUUID: "",
  uuid: "",
  contentType: "url",
  contentTitle: "",
  contentCreationDate: "",
  contentLastModifiedDate: "",
  contentFileUUID: "",
});
