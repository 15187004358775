import { FC, PropsWithChildren } from "react";

import { Route, Routes } from "react-router-dom";

import { Box } from "@fastly/beacon";

import { MessageDetailsPage } from "customer/pages/MessageDetails";
import { MessagesListPage } from "customer/pages/MessagesListPage";
import { ProfessionalServicesPage } from "professional-services";

export const CustomerApp: FC<PropsWithChildren> = () => {
  // This can be removed in production, it is used to test the error boundary functionality
  if (window.location.toString().includes("__trigger_error_boundary"))
    throw new Error("Error Boundary Triggered!");

  return (
    <Box className="fui-chrome__content__container" id="comms-hub-customer-ui">
      <Routes>
        <Route element={<MessagesListPage />} path="/account/notifications" />
        <Route
          element={<ProfessionalServicesPage />}
          path="/account/professional-services"
        />
        <Route
          element={<MessageDetailsPage />}
          path="/account/notifications/:messageID"
        />
      </Routes>
    </Box>
  );
};
