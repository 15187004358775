import { formatInTimeZone, toZonedTime } from "date-fns-tz";

const DATE_FORMAT = "yyyy-MM-dd hh:mm";

export interface FormattableTimestamp {
  unixTimestamp: number;
  timezone: string;
}
export const getDateString = (
  timestamp: number | FormattableTimestamp,
): string => {
  const useBrowserTimezone = typeof timestamp === "number";
  return formatInTimeZone(
    new Date(useBrowserTimezone ? timestamp : timestamp.unixTimestamp),
    useBrowserTimezone
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : timestamp.timezone,
    DATE_FORMAT,
  );
};

export const getServerSideDateString = (timestamp: number): string => {
  return `${new Date(timestamp).toISOString().replace("Z", "")}+00:00`;
};

export const getLocalDateStringFromUtcDate = (dateStr: string): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = toZonedTime(dateStr, timezone);

  return formatInTimeZone(date, timezone, DATE_FORMAT);
};
