import { FC, PropsWithChildren } from "react";

import { cx } from "@emotion/css";

import { Text } from "@fastly/beacon";

import * as styleUtils from "styles/utils.css";

import * as styles from "./Message.css";

type FCWithChildren = FC<PropsWithChildren>;

export const Message: FCWithChildren = ({ children }) => (
  <article className={cx(styles.root, styleUtils.stack.none)}>
    {children}
  </article>
);

export const MessageBody: FCWithChildren = ({ children }) => (
  <Text className={styles.messageBody} weight="normal">
    {children}
  </Text>
);
