import { Instantiate, Server } from "miragejs";

import { AppRegistry } from "mirage/route-handlers/helpers";

import { getServerSideDateString } from "../../dates/dateString";

type SetupMessagesOptions = {
  server: Server<AppRegistry>;
};

const exampleDate = getServerSideDateString(1600000000000);
const exampleDateNew = getServerSideDateString(1600020000000);

export default function setupMessage({
  server,
}: SetupMessagesOptions): Instantiate<AppRegistry, "message"> {
  server.create("message", {
    _id: 1,
    messageTitle:
      "Retrospective - Issues with Fastly Corporate site (www.fastly.com)",
    uuid: "abcdef",
    messageBody:
      "We recently shared a Fastly Service Advisory in response to the incident communicated on the Fastly status page and public blog post. Fastly intends to release a Post Retrospective Summary for this incident and supporting processes, which will include a summary of our remediations and forward planning discussions to prevent risk of future occurrences.",
    sender: "Incident response",
    createdDate: exampleDateNew,
    lastModifiedDate: exampleDateNew,
    createdByAdminID: "",
  });

  const message = server.create("message", {
    _id: 2,
    messageTitle: "Shield migration",
    uuid: "ghijklm",
    messageBody:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    sender: "Customer support",
    createdDate: exampleDate,
    lastModifiedDate: exampleDate,
    createdByAdminID: "",
  });

  server.create("linkedContent", {
    _id: 1,
    uuid: "5t4yhrgbfdvet35h",
    messageUUID: "ghijklm",
    messageID: 1,
    contentType: "file",
    contentFileUUID: "abcdefg",
    contentTitle: "Download FSA",
    contentCreationDate: exampleDate,
    contentLastModifiedDate: exampleDate,
  });

  server.create("linkedContent", {
    _id: 2,
    uuid: "dksgfjeqpoi",
    messageUUID: "ghijklm",
    messageID: 1,
    contentType: "url",
    contentURL: "https://www.fastlystatus.com/",
    contentTitle: "View status",
    contentCreationDate: exampleDate,
    contentLastModifiedDate: exampleDate,
  });

  server.create("delivery", {
    _id: 1,
    uuid: "gfsdbfsvayhtjfnmgdb",
    customerID: "123",
    messageID: 1,
    minimumRole: "superuser",
    createdDate: exampleDate,
    deliveryDate: exampleDate,
    lastModifiedDate: exampleDate,
  });
  server.create("delivery", {
    _id: 2,
    uuid: "gdsyfthkj,mnfdhs",
    customerID: "123",
    messageID: 2,
    minimumRole: "superuser",
    createdDate: exampleDate,
    deliveryDate: exampleDate,
    lastModifiedDate: exampleDate,
  });
  server.create("delivery", {
    _id: 3,
    uuid: "aeyrstdjhgnmfbdsewr",
    customerID: "789",
    messageID: 1,
    minimumRole: "superuser",
    createdDate: exampleDate,
    deliveryDate: exampleDate,
    lastModifiedDate: exampleDate,
  });

  server.create("delivery", {
    _id: 4,
    uuid: "bfrdaegyhryjngfdbhr",
    customerID: "invalid-cid",
    messageID: 1,
    minimumRole: "superuser",
    createdDate: exampleDate,
    deliveryDate: exampleDate,
    lastModifiedDate: exampleDate,
  });

  return message;
}
