import { BaseModel } from "./base-model";

export type FileUploadAttrs = {
  filename: string;
  uuid: string;
};

export const FileUpload = BaseModel.extend<FileUploadAttrs>({
  filename: "",
  uuid: "",
});
