import { Pagination, usePaginationOffset } from "@fastly/beacon";

import { PaginationInfo } from "api/pagination";

export const PaginationToolbar = ({
  pagination,
}: {
  pagination: PaginationInfo;
}) => {
  const paginationProps = usePaginationOffset({
    initLimit: pagination.pageSize,
    limitOptions: [5, 10, 20, 100],
    recordCount: pagination.totalCount ?? 0,
    onChange: ({ limit, pageNum }) => {
      if (limit && limit > 0 && limit !== pagination.pageSize) {
        pagination.setLimit(limit);
      }

      /**
       * pageNum reflects the UI and therefore begins at 1, pagination.page
       * begins at 0, so we need to offset those when comparing
       */
      if (pageNum && pageNum > pagination.page + 1) {
        pagination.next();
      }

      if (pageNum && pageNum < pagination.page) {
        pagination.prev();
      }
    },
  });

  if (pagination.totalCount === 0) return null;

  return <Pagination {...paginationProps} />;
};
