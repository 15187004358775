import { Box } from "@fastly/beacon";

import { Text } from "beacon-staging/primitives/Text";

export const EmptyState = ({ message }: { message: string }) => (
  <Box
    borderColor="muted"
    borderStyle="solid"
    borderWidth="light"
    marginBottom="lg"
    marginTop="md"
    padding="xl"
  >
    <Text>{message}</Text>
  </Box>
);
