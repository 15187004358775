import { useCallback } from "react";

import { useUpdateReadStateByMessageUuidMutation } from "api";

export const useUpdateReadState = () => {
  const [updateReadState, updateReadStateStatus] =
    useUpdateReadStateByMessageUuidMutation();

  const markAsRead = useCallback(
    async (messageUuid: string, readDate = new Date().toISOString()) => {
      return updateReadState({
        messageUuid,
        deliveredMessageUpdatePayload: { readDate },
      });
    },
    [updateReadState],
  );

  const markAsUnread = useCallback(
    async (messageUuid: string) => {
      return updateReadState({
        messageUuid,
        deliveredMessageUpdatePayload: {
          readDate: null,
        },
      });
    },
    [updateReadState],
  );

  return {
    markAsRead,
    markAsUnread,
    status: updateReadStateStatus,
  };
};
