import { BaseModel } from "./base-model";

export type MessageAttrs = {
  // id seems to be reserved by mirage or something
  _id: number;
  // createdByAdminID: string;
  uuid: string;
  sender: string;
  messageTitle: string;
  messageBody: string;
  createdDate: string;
  lastModifiedDate: string;
  messageReadDate: string | null;
};

export const Message = BaseModel.extend<MessageAttrs>({
  _id: 0,
  uuid: "",
  sender: "",
  messageTitle: "",
  messageBody: "",
  createdDate: "",
  lastModifiedDate: "",
  messageReadDate: null,
});
